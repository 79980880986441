<template lang="pug">
  include /mixins
  +b-context('table')
    +e.TBODY.tbody
      +e.TR.row
        //- +e.TD.cell--bordered_left_top.--bordered_left_bottom
          row(
            alignment='center' 
            space='md' 
            nowrap
          )
            cell()
              slot(name='checkbox')
            
            cell()
              v-button(
                variant='clear' 
                @click='toggleExpand(user.id)' 
                style='cursor:pointer'
                alignment='center'
                nowrap
              )
                cell()
                  v-button-element
                    v-caption(
                      size='6' 
                      color='default'
                      variant='bold'
                    ) {{ user.first_name }} {{ user.last_name }}
                template(v-slot:append='')
                  cell
                    v-button-element()
                      icon(
                        :type='isExpanded ? "uparrow" : "downarrow"' 
                        size='3xs'
                        color='brand-4'
                      )
        +e.TD.cell 
          v-caption(
            size='6' 
            color='default'
            variant='bold'
          ) {{ user.email }}
        +e.TD.cell 
          v-caption(
            size='6' 
            color='default'
            variant='bold'
          ) {{ user.phone }}
        +e.TD.cell 
          v-caption(
            size='6' 
            color='default'
            variant='bold'
          ) {{ user.city }}
        +e.TD.cell 
          v-caption(
            size='6' 
            color='default'
            variant='bold'
          ) {{ user.subdivision }}
        +e.TD.cell 
          v-caption(
            size='6' 
            color='default'
            variant='bold'
          ) {{ user.country }}
        +e.TD.cell 
          v-caption(
            size='6' 
            color='default'
            variant='bold'
          ) {{ user.orders }}
        +e.TD.cell--bordered_right_top.--bordered_right_bottom 
          v-caption(
            size='6' 
            color='default'
            variant='bold'
          ) $ {{ user.orders_sum | toUSD }}
      +e.TR.row(v-if='isExpanded')
        +e.TD.cell.--bordered_left_top.--bordered_left_bottom.--bordered_right_top.--bordered_right_bottom(colspan='8')
          row(offset)
            cell()
              div(v-show='!true')
                v-checkbox()
                  +b.INPUT(type="checkbox").control-checkbox__input

            cell(cols='12 10-md 8-lg 6-xl 4-2xl')
              row(offset)
                cell(cols='12')
                  slot(name='address')
                cell(cols='12' v-if='userDetails.length')
                  UserDetailsTable(:user-details='userDetails')






</template>

<script>
import UserService from '@cabinet/services/api/users'
import VendorsService from '@cabinet/services/api/vendors'
import { ref } from '@vue/composition-api'
import { useResponse } from '@cabinet/composables/use-response'
import UserDetailsTable from '@cabinet/components/users/UserDetailsTable'

export default {
  components: {
    UserDetailsTable,
  },
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  setup(p, c) {
    const isExpanded = ref(false)
    const toggleExpand = (id) => {
      isExpanded.value = !isExpanded.value
      console.log(isExpanded)
      if (isExpanded.value) {
        fetchUser(id)
      }
    }
    const userDetails = ref([])

    const fetchUser = async (id) => {

      let service = UserService
      if (c.root.$store.state.userModule.userData.is_vendor) {
        service = VendorsService
      } 
      try {
        const res = await useResponse(service.fetchOne(id), c.root, null)
        userDetails.value = res.data
        
      } catch (error) {
        console.error(error)
      }
      
    }
    return {
      isExpanded,
      userDetails,

      toggleExpand
    }
  }
}
</script>